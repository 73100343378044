import { App as AntApp, ConfigProvider } from 'antd';
import arEG from 'antd/locale/ar_EG';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'urql';
import { graphqlClient } from './graphql-client';
import Routes from './routes/routes';

function App() {
  return (
    <ConfigProvider direction="rtl" locale={arEG}>
      <Provider value={graphqlClient}>
        <HelmetProvider>
          <AntApp>
            <Routes />
          </AntApp>
        </HelmetProvider>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
