import { Result, Spin } from 'antd';
import React from 'react';
import { useTypeSafeTranslation } from '../modules/hooks/useTypeSafeTranslation';

const Loading = () => {
  const { t } = useTypeSafeTranslation();
  return React.createElement(Result, {
    icon: React.createElement(Spin, { size: 'large' }),
    title: t('loading'),
  });
};

export default Loading;
