import { Client, cacheExchange, fetchExchange } from 'urql';
import { graphqlAPI } from './constants';
import keycloak from './keycloak-client';

export const graphqlClient = new Client({
  url: graphqlAPI,
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    if (keycloak.authenticated) keycloak.updateToken();
    return {
      headers: {
        authorization: keycloak.authenticated ? `Bearer ${keycloak.token}` : '',
      },
    };
  },
});
