import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'antd/dist/reset.css';
import 'virtual:uno.css';

import './i18n';
import { initKeycloak } from './keycloak-client';

const el = document.getElementById('root') as HTMLElement;
const root = createRoot(el);
initKeycloak().then(() =>
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  ),
);
