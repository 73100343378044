import { useTranslation } from 'react-i18next';

import type translations from '../../../public/locales/ar/translation.json';
import type { Paths } from '../../@types/util-types';

type TranslationKeys = Paths<typeof translations>;

interface DateTranslationType {
  time?: Date;
  date?: Date;
}

export const useTypeSafeTranslation = () => {
  const { t } = useTranslation();

  return {
    t: (
      s: TranslationKeys,
      a?: DateTranslationType | { [key: string]: string },
    ) => t(s, a),
  };
};
