import Keycloak from 'keycloak-js';
import { __prod__, keycloakClientId, keycloakURL } from './constants';

const keycloak = new Keycloak({
  url: keycloakURL,
  realm: 'daftaron',
  clientId: keycloakClientId,
});

export const initKeycloak = async () => {
  await keycloak.init({
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    enableLogging: __prod__,
  });
};

export default keycloak;
