import { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Loading from '../components/loading';
const DashboardRoutes = lazy(() => import('./root/dashboard/routes'));
const ReportsRoutes = lazy(() => import('./root/reports/routes'));
const Root = lazy(() => import('./root'));
const ErrorPage = lazy(() => import('./error-page'));
const RequireAuth = lazy(() => import('./require-auth'));

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Root />,
      },
      {
        path: 'dashboard/*',
        element: (
          <RequireAuth>
            <DashboardRoutes />
          </RequireAuth>
        ),
      },
      {
        path: 'reports/*',
        element: (
          <RequireAuth>
            <ReportsRoutes />
          </RequireAuth>
        ),
      },
    ],
  },
]);

const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
